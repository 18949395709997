import React from 'react';
import './About.css'

function About() {


    return(

        <div>
            <h1>
                About Us
            </h1>
            <p>
                Sample About Page
            </p>
        </div>
    );
}

export default About;